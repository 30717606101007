import React, { useState, useRef } from 'react';
import { Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '../../utils/useMediaQuery';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './CardOfferList.module.scss';

import CardOffer from '../CardOffer';

const CardOfferList = ({ title = '', cardItems = [] }) => {
    if (!cardItems.length) return null;

    const [hideNextButton, setHideNextButton] = useState(false);
    const [hidePreviousButton, setHidePreviousButton] = useState(false);

    const isBreakpoint = cardItems.length > 3 ? useMediaQuery(1024) : false;
    const isSwiper = cardItems.length > 3 && isBreakpoint;

    const classes = classNames(styles['CardOfferList__Items'], {
        [styles['CardOfferList__Items--Column-3']]: cardItems.length === 3,
    });

    const sliderSettings = {
        modules: { Scrollbar, A11y, Navigation },
        spaceBetween: 24,
        slidesPerView: 3,
        noSwipingSelector: 'a',
        scrollbar: { draggable: true },
        mousewheel: {
            forceToAxis: true,
            releaseOnEdges: true,
        },
    };

    const setModifiers = (cardItems, index) => {
        const modifiers = [];

        if (cardItems.length > 0) {
            const numberOfCards = cardItems.length;

            numberOfCards > 2 ? modifiers.push('small') : '';
            numberOfCards === 3 && index === 0
                ? modifiers.push('highlight')
                : '';
        }

        return modifiers;
    };

    const swiperRef = useRef();
    return (
        <section className={styles['CardOfferList']}>
            {title && (
                <div className={styles['CardOfferList__Container']}>
                    <h2 className={styles['CardOfferList__Title']}>{title}</h2>
                </div>
            )}
            <div className={styles['CardOfferList__Inner']}>
                {isSwiper && !hidePreviousButton && (
                    <div className={styles['CardOfferList__LeftGradient']}>
                        <button
                            className={styles['CardOfferList__PreviousButton']}
                            onClick={() => swiperRef.current?.slidePrev()}>
                            <span className="sr-only">Previous</span>
                        </button>
                    </div>
                )}
                <div className={styles['CardOfferList__Container']}>
                    {isSwiper ? (
                        <Swiper
                            className={styles['CardOfferList__Swiper']}
                            {...sliderSettings}
                            onSlideChange={(swiper) => {
                                setHidePreviousButton(swiper.isBeginning);
                                setHideNextButton(swiper.isEnd);
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                                setHidePreviousButton(swiper.isBeginning);
                                setHideNextButton(swiper.isEnd);
                            }}>
                            {cardItems.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <CardOffer
                                        modifiers={['small']}
                                        {...item}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        title && (
                            <div className={classes}>
                                {cardItems.map((item, index) => (
                                    <CardOffer
                                        key={index}
                                        modifiers={setModifiers(
                                            cardItems,
                                            index
                                        )}
                                        {...item}
                                    />
                                ))}
                            </div>
                        )
                    )}
                </div>
                {isSwiper && !hideNextButton && (
                    <div className={styles['CardOfferList__RightGradient']}>
                        <button
                            className={styles['CardOfferList__NextButton']}
                            onClick={() => swiperRef.current?.slideNext()}>
                            <span className="sr-only">Next</span>
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
};

CardOfferList.propTypes = {
    cardItems: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.shape({
                title: PropTypes.string,
                href: PropTypes.string,
                target: PropTypes.string,
            }),
            label: PropTypes.string,
            title: PropTypes.string,
            tagCopy: PropTypes.string,
            text: PropTypes.string,
            bulletItems: PropTypes.arrayOf(PropTypes.string),
        })
    ),
};

export default CardOfferList;
