import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ucFirst } from '../../utils/caseconverters';
import styles from './CardOffer.module.scss';

const CardOffer = ({
    label = '',
    title = '',
    tagText = '',
    text = '',
    link = {},
    bulletItems = [],
    modifiers = [],
    handleLinkClick,
}) => {
    if (!link) return null;

    const [isActive, setActive] = useState(false);

    const handleClick = (e, title) => {
        window._mtm = window._mtm || [];
        window._mtm.push({
            ctaButtonName: title,
            event: 'interactionsCallToActionButton',
        });

        if (handleLinkClick) {
            handleLinkClick(e, null);
        }
    };

    return (
        <article
            className={classNames(
                styles['CardOffer'],
                modifiers.map(
                    (modifier) =>
                        styles['CardOffer--' + ucFirst(modifier)] + ' '
                ),
                {
                    [styles['CardOffer--Hover']]: isActive,
                }
            )}>
            <div className={styles['CardOffer__Header']}>
                <div>
                    <div className={styles['CardOffer__Label']}>{label}</div>
                    <h3 className={styles['CardOffer__Title']}>{title}</h3>
                </div>
                {tagText && (
                    <span className={styles['CardOffer__Tag']}>{tagText}</span>
                )}
            </div>
            <div className={styles['CardOffer__Content']}>
                {text && <p className={styles['CardOffer__Text']}>{text}</p>}
                {!!bulletItems && (
                    <ul className={styles['CardOffer__List']}>
                        {bulletItems.map((item, i) => (
                            <li
                                key={i}
                                className={styles['CardOffer__ListItem']}>
                                {item}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {!!link.title && (
                <div className={styles['CardOffer__ButtonContainer']}>
                    <a
                        href={link.href}
                        target={link.target}
                        className={styles['CardOffer__Button']}
                        onClick={(e) => handleClick(e, link.title)}
                        onFocus={() => setActive(true)}
                        onBlur={() => setActive(false)}
                        onMouseEnter={() => setActive(true)}
                        onMouseLeave={() => setActive(false)}>
                        {link.title}
                    </a>
                </div>
            )}
        </article>
    );
};

CardOffer.propTypes = {
    link: PropTypes.shape({
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    label: PropTypes.string,
    title: PropTypes.string,
    tagText: PropTypes.string,
    text: PropTypes.string,
    bulletItems: PropTypes.array,
    primaryButtonText: PropTypes.string,
};

export default CardOffer;
